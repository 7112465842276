import routerOptions0 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.4_eslint@8.57.0_floating-vue@5.2.2_ro_2nwyxrbydlkcb72zxj5imipulm/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/opt/build/repo/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}