import type { RouterConfig } from '@nuxt/schema'

export default <RouterConfig>{
  scrollBehavior(_to, _from, savedPosition) {
    const nuxtApp = useNuxtApp()

    if (savedPosition) {
      // Handle Suspense resolution
      return new Promise((resolve) => {
        nuxtApp.hooks.hookOnce('page:finish', () => {
          setTimeout(() => {
            resolve(savedPosition)
          }, 50)
        })
      })
    }

    return new Promise((resolve) => {
      nuxtApp.hooks.hook('page:finish', async () => {
        await nextTick()
        resolve({ top: 0 })
      })
    })
  }
}
