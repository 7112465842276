
// @ts-nocheck


export const localeCodes =  [
  "sl",
  "en",
  "de"
]

export const localeLoaders = {
  "sl": [{ key: "../locales/sl.json", load: () => import("../locales/sl.json" /* webpackChunkName: "locale__opt_build_repo_locales_sl_json" */), cache: true }],
  "en": [{ key: "../locales/en.json", load: () => import("../locales/en.json" /* webpackChunkName: "locale__opt_build_repo_locales_en_json" */), cache: true }],
  "de": [{ key: "../locales/de.json", load: () => import("../locales/de.json" /* webpackChunkName: "locale__opt_build_repo_locales_de_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n/i18n.config.ts?hash=5b32d2e0&config=1" /* webpackChunkName: "__i18n_i18n_config_ts_5b32d2e0" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n/i18n.config.ts",
  "locales": [
    {
      "code": "sl",
      "iso": "sl-SI",
      "name": "Slovenščina",
      "files": [
        "locales/sl.json"
      ]
    },
    {
      "code": "en",
      "iso": "en-US",
      "name": "English",
      "files": [
        "locales/en.json"
      ]
    },
    {
      "code": "de",
      "iso": "de-DE",
      "name": "Deutsch",
      "files": [
        "locales/de.json"
      ]
    }
  ],
  "defaultLocale": "sl",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": "locales",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "https://stajerska.si",
  "dynamicRouteParams": true,
  "customRoutes": "config",
  "pages": {
    "[slug]": {
      "sl": "/[slug]",
      "en": "/[slug]",
      "de": "/[slug]"
    },
    "news": {
      "en": "/news",
      "sl": "/novice",
      "de": "/news"
    },
    "news/[slug]": {
      "en": "/news/[slug]",
      "sl": "/novice/[slug]",
      "de": "/news/[slug]"
    },
    "offers": {
      "sl": "/ponudbe",
      "en": "/offers",
      "de": "/angebote"
    },
    "offers/[slug]": {
      "en": "/offers/[slug]",
      "sl": "/ponudbe/[slug]",
      "de": "/angebote/[slug]"
    },
    "events": {
      "sl": "/dogodki",
      "en": "/events",
      "de": "/events"
    },
    "events/[slug]": {
      "en": "/events/[slug]",
      "sl": "/dogodki/[slug]",
      "de": "/events/[slug]"
    },
    "cycling-routes": {
      "sl": "/kolesarske-poti",
      "en": "/cycling-routes",
      "de": "/fahrradwege"
    },
    "cycling-routes/[slug]": {
      "en": "/cycling-routes/[slug]",
      "sl": "/kolesarske-poti/[slug]",
      "de": "/fahrradwege/[slug]"
    }
  },
  "skipSettingLocaleOnNavigate": true,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "sl",
    "iso": "sl-SI",
    "name": "Slovenščina",
    "files": [
      {
        "path": "locales/sl.json"
      }
    ]
  },
  {
    "code": "en",
    "iso": "en-US",
    "name": "English",
    "files": [
      {
        "path": "locales/en.json"
      }
    ]
  },
  {
    "code": "de",
    "iso": "de-DE",
    "name": "Deutsch",
    "files": [
      {
        "path": "locales/de.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = true

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
