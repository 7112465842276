import { defineNuxtPlugin } from '#app/nuxt'
import { LazySectionArticles, LazySectionBody, LazySectionContactForm, LazySectionCta, LazySectionDestinationMap, LazySectionEvents, LazySectionExperiences, LazySectionFeatures, LazySectionGettingHere, LazySectionHero, LazySectionHeroCenter, LazySectionHeroSimple, LazySectionImageGallery, LazySectionImageLinks, LazySectionLinks, LazySectionLocation, LazySectionLogos, LazySectionMapEmbed, LazySectionMedia, LazySectionNewsletter, LazySectionOffers, LazySectionOffersSlider, LazySectionRelatedArticles, LazySectionRoadmap, LazySectionRoutes, LazySectionRoutesGrid, LazySectionSearch, LazySectionSlider, LazySectionSwitcherOne, LazySectionSwitcherTwo, LazySectionTestimonials, LazySectionTitle, LazySectionVideo } from '#components'
const lazyGlobalComponents = [
  ["SectionArticles", LazySectionArticles],
["SectionBody", LazySectionBody],
["SectionContactForm", LazySectionContactForm],
["SectionCta", LazySectionCta],
["SectionDestinationMap", LazySectionDestinationMap],
["SectionEvents", LazySectionEvents],
["SectionExperiences", LazySectionExperiences],
["SectionFeatures", LazySectionFeatures],
["SectionGettingHere", LazySectionGettingHere],
["SectionHero", LazySectionHero],
["SectionHeroCenter", LazySectionHeroCenter],
["SectionHeroSimple", LazySectionHeroSimple],
["SectionImageGallery", LazySectionImageGallery],
["SectionImageLinks", LazySectionImageLinks],
["SectionLinks", LazySectionLinks],
["SectionLocation", LazySectionLocation],
["SectionLogos", LazySectionLogos],
["SectionMapEmbed", LazySectionMapEmbed],
["SectionMedia", LazySectionMedia],
["SectionNewsletter", LazySectionNewsletter],
["SectionOffers", LazySectionOffers],
["SectionOffersSlider", LazySectionOffersSlider],
["SectionRelatedArticles", LazySectionRelatedArticles],
["SectionRoadmap", LazySectionRoadmap],
["SectionRoutes", LazySectionRoutes],
["SectionRoutesGrid", LazySectionRoutesGrid],
["SectionSearch", LazySectionSearch],
["SectionSlider", LazySectionSlider],
["SectionSwitcherOne", LazySectionSwitcherOne],
["SectionSwitcherTwo", LazySectionSwitcherTwo],
["SectionTestimonials", LazySectionTestimonials],
["SectionTitle", LazySectionTitle],
["SectionVideo", LazySectionVideo],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
