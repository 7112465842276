<template>
  <div>
    <NuxtLayout>
      <NuxtPage
        :transition="{
          name: 'page',
          mode: 'out-in',
          onBeforeEnter
        }"
      />
    </NuxtLayout>
    <LazyCookieConsent v-if="loadCookieBanner" />
  </div>
</template>

<script setup lang="ts">
import { useAppStore } from '~/stores/app'

const appConfig = useAppConfig()
const store = useAppStore()
const { locale } = useI18n()

store.fetchData(locale.value)

const { finalizePendingLocaleChange } = useI18n()

const onBeforeEnter = async () => {
  store.setLoading(true)
  await finalizePendingLocaleChange()
  await store.fetchData(locale.value)
}

// Delay loading of cookie banner
const loadCookieBanner = ref(false)
onMounted(() => {
  useTimeoutFn(() => {
    loadCookieBanner.value = true
  }, 3000)
})

useHead({
  titleTemplate: (titleChunk) => (titleChunk ? `${titleChunk}` : appConfig.title)
})
</script>
