import { default as _91slug_93RPYB4NvKKjMeta } from "/opt/build/repo/pages/[slug].vue?macro=true";
import { default as _91slug_939cYfrYjaXlMeta } from "/opt/build/repo/pages/cycling-routes/[slug].vue?macro=true";
import { default as _91slug_93XqrceGdm0QMeta } from "/opt/build/repo/pages/events/[slug].vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as _91slug_93T6Ht95H20AMeta } from "/opt/build/repo/pages/news/[slug].vue?macro=true";
import { default as _91slug_93L3d3uDd4xpMeta } from "/opt/build/repo/pages/offers/[slug].vue?macro=true";
export default [
  {
    name: _91slug_93RPYB4NvKKjMeta?.name ?? "slug___sl",
    path: _91slug_93RPYB4NvKKjMeta?.path ?? "/:slug()",
    meta: _91slug_93RPYB4NvKKjMeta || {},
    alias: _91slug_93RPYB4NvKKjMeta?.alias || [],
    redirect: _91slug_93RPYB4NvKKjMeta?.redirect,
    component: () => import("/opt/build/repo/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RPYB4NvKKjMeta?.name ?? "slug___en",
    path: _91slug_93RPYB4NvKKjMeta?.path ?? "/en/:slug()",
    meta: _91slug_93RPYB4NvKKjMeta || {},
    alias: _91slug_93RPYB4NvKKjMeta?.alias || [],
    redirect: _91slug_93RPYB4NvKKjMeta?.redirect,
    component: () => import("/opt/build/repo/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RPYB4NvKKjMeta?.name ?? "slug___de",
    path: _91slug_93RPYB4NvKKjMeta?.path ?? "/de/:slug()",
    meta: _91slug_93RPYB4NvKKjMeta || {},
    alias: _91slug_93RPYB4NvKKjMeta?.alias || [],
    redirect: _91slug_93RPYB4NvKKjMeta?.redirect,
    component: () => import("/opt/build/repo/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939cYfrYjaXlMeta?.name ?? "cycling-routes-slug___sl",
    path: _91slug_939cYfrYjaXlMeta?.path ?? "/kolesarske-poti/:slug()",
    meta: _91slug_939cYfrYjaXlMeta || {},
    alias: _91slug_939cYfrYjaXlMeta?.alias || [],
    redirect: _91slug_939cYfrYjaXlMeta?.redirect,
    component: () => import("/opt/build/repo/pages/cycling-routes/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939cYfrYjaXlMeta?.name ?? "cycling-routes-slug___en",
    path: _91slug_939cYfrYjaXlMeta?.path ?? "/en/cycling-routes/:slug()",
    meta: _91slug_939cYfrYjaXlMeta || {},
    alias: _91slug_939cYfrYjaXlMeta?.alias || [],
    redirect: _91slug_939cYfrYjaXlMeta?.redirect,
    component: () => import("/opt/build/repo/pages/cycling-routes/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939cYfrYjaXlMeta?.name ?? "cycling-routes-slug___de",
    path: _91slug_939cYfrYjaXlMeta?.path ?? "/de/fahrradwege/:slug()",
    meta: _91slug_939cYfrYjaXlMeta || {},
    alias: _91slug_939cYfrYjaXlMeta?.alias || [],
    redirect: _91slug_939cYfrYjaXlMeta?.redirect,
    component: () => import("/opt/build/repo/pages/cycling-routes/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93XqrceGdm0QMeta?.name ?? "events-slug___sl",
    path: _91slug_93XqrceGdm0QMeta?.path ?? "/dogodki/:slug()",
    meta: _91slug_93XqrceGdm0QMeta || {},
    alias: _91slug_93XqrceGdm0QMeta?.alias || [],
    redirect: _91slug_93XqrceGdm0QMeta?.redirect,
    component: () => import("/opt/build/repo/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93XqrceGdm0QMeta?.name ?? "events-slug___en",
    path: _91slug_93XqrceGdm0QMeta?.path ?? "/en/events/:slug()",
    meta: _91slug_93XqrceGdm0QMeta || {},
    alias: _91slug_93XqrceGdm0QMeta?.alias || [],
    redirect: _91slug_93XqrceGdm0QMeta?.redirect,
    component: () => import("/opt/build/repo/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93XqrceGdm0QMeta?.name ?? "events-slug___de",
    path: _91slug_93XqrceGdm0QMeta?.path ?? "/de/events/:slug()",
    meta: _91slug_93XqrceGdm0QMeta || {},
    alias: _91slug_93XqrceGdm0QMeta?.alias || [],
    redirect: _91slug_93XqrceGdm0QMeta?.redirect,
    component: () => import("/opt/build/repo/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index___sl",
    path: indexlaFeZuYZkUMeta?.path ?? "/",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index___en",
    path: indexlaFeZuYZkUMeta?.path ?? "/en",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index___de",
    path: indexlaFeZuYZkUMeta?.path ?? "/de",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93T6Ht95H20AMeta?.name ?? "news-slug___sl",
    path: _91slug_93T6Ht95H20AMeta?.path ?? "/novice/:slug()",
    meta: _91slug_93T6Ht95H20AMeta || {},
    alias: _91slug_93T6Ht95H20AMeta?.alias || [],
    redirect: _91slug_93T6Ht95H20AMeta?.redirect,
    component: () => import("/opt/build/repo/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93T6Ht95H20AMeta?.name ?? "news-slug___en",
    path: _91slug_93T6Ht95H20AMeta?.path ?? "/en/news/:slug()",
    meta: _91slug_93T6Ht95H20AMeta || {},
    alias: _91slug_93T6Ht95H20AMeta?.alias || [],
    redirect: _91slug_93T6Ht95H20AMeta?.redirect,
    component: () => import("/opt/build/repo/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93T6Ht95H20AMeta?.name ?? "news-slug___de",
    path: _91slug_93T6Ht95H20AMeta?.path ?? "/de/news/:slug()",
    meta: _91slug_93T6Ht95H20AMeta || {},
    alias: _91slug_93T6Ht95H20AMeta?.alias || [],
    redirect: _91slug_93T6Ht95H20AMeta?.redirect,
    component: () => import("/opt/build/repo/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93L3d3uDd4xpMeta?.name ?? "offers-slug___sl",
    path: _91slug_93L3d3uDd4xpMeta?.path ?? "/ponudbe/:slug()",
    meta: _91slug_93L3d3uDd4xpMeta || {},
    alias: _91slug_93L3d3uDd4xpMeta?.alias || [],
    redirect: _91slug_93L3d3uDd4xpMeta?.redirect,
    component: () => import("/opt/build/repo/pages/offers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93L3d3uDd4xpMeta?.name ?? "offers-slug___en",
    path: _91slug_93L3d3uDd4xpMeta?.path ?? "/en/offers/:slug()",
    meta: _91slug_93L3d3uDd4xpMeta || {},
    alias: _91slug_93L3d3uDd4xpMeta?.alias || [],
    redirect: _91slug_93L3d3uDd4xpMeta?.redirect,
    component: () => import("/opt/build/repo/pages/offers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93L3d3uDd4xpMeta?.name ?? "offers-slug___de",
    path: _91slug_93L3d3uDd4xpMeta?.path ?? "/de/angebote/:slug()",
    meta: _91slug_93L3d3uDd4xpMeta || {},
    alias: _91slug_93L3d3uDd4xpMeta?.alias || [],
    redirect: _91slug_93L3d3uDd4xpMeta?.redirect,
    component: () => import("/opt/build/repo/pages/offers/[slug].vue").then(m => m.default || m)
  }
]