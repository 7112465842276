import { ModelApiKey } from '../types/models'
import { LocaleCode } from '../types/locales'

export const paths = {
  [LocaleCode.SL]: {
    [ModelApiKey.Page]: { path: '' },
    [ModelApiKey.Article]: { path: 'novice' },
    [ModelApiKey.Event]: { path: 'dogodki' },
    [ModelApiKey.Offer]: { path: 'ponudbe' },
    [ModelApiKey.Route]: { path: 'kolesarske-poti' }
  },
  [LocaleCode.EN]: {
    [ModelApiKey.Page]: { path: '' },
    [ModelApiKey.Article]: { path: 'news' },
    [ModelApiKey.Event]: { path: 'events' },
    [ModelApiKey.Offer]: { path: 'offers' },
    [ModelApiKey.Route]: { path: 'cycling-routes' }
  },
  [LocaleCode.DE]: {
    [ModelApiKey.Page]: { path: '' },
    [ModelApiKey.Article]: { path: 'news' },
    [ModelApiKey.Event]: { path: 'events' },
    [ModelApiKey.Offer]: { path: 'angebote' },
    [ModelApiKey.Route]: { path: 'fahrradwege' }
  }
}
