import { useGql } from '#imports'
import { getSdk as defaultGqlSdk } from '#gql/default'
export const GqlSdks = {
  default: defaultGqlSdk,
}
export const GqClientOps = {"default":["Site","articles","article","audiences","SectionBlocks","categories","destinations","eventCategories","events","event","calendarDates","Page","Image","CallToAction","HeroBlocks","index","offerCategories","offers","offer","pages","page","routes","route"]}
export const GqlSite = (...params) => useGql()('Site', ...params)
export const GqlArticle = (...params) => useGql()('article', ...params)
export const GqlArticles = (...params) => useGql()('articles', ...params)
export const GqlAudiences = (...params) => useGql()('audiences', ...params)
export const GqlCalendarDates = (...params) => useGql()('calendarDates', ...params)
export const GqlCategories = (...params) => useGql()('categories', ...params)
export const GqlDestinations = (...params) => useGql()('destinations', ...params)
export const GqlEvent = (...params) => useGql()('event', ...params)
export const GqlEventCategories = (...params) => useGql()('eventCategories', ...params)
export const GqlEvents = (...params) => useGql()('events', ...params)
export const GqlIndex = (...params) => useGql()('index', ...params)
export const GqlOffer = (...params) => useGql()('offer', ...params)
export const GqlOfferCategories = (...params) => useGql()('offerCategories', ...params)
export const GqlOffers = (...params) => useGql()('offers', ...params)
export const GqlPage = (...params) => useGql()('page', ...params)
export const GqlPages = (...params) => useGql()('pages', ...params)
export const GqlRoute = (...params) => useGql()('route', ...params)
export const GqlRoutes = (...params) => useGql()('routes', ...params)