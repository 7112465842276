import revive_payload_client_mHU7AEErat from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.4_eslint@8.57.0_floating-vue@5.2.2_ro_2nwyxrbydlkcb72zxj5imipulm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_gCLydIO5af from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.4_eslint@8.57.0_floating-vue@5.2.2_ro_2nwyxrbydlkcb72zxj5imipulm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_EfORKqxfCY from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.4_eslint@8.57.0_floating-vue@5.2.2_ro_2nwyxrbydlkcb72zxj5imipulm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_AveYsmTbJo from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.4_eslint@8.57.0_floating-vue@5.2.2_ro_2nwyxrbydlkcb72zxj5imipulm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_Beedx3MULO from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.4_eslint@8.57.0_floating-vue@5.2.2_ro_2nwyxrbydlkcb72zxj5imipulm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_hUtmV96iV1 from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.18.0_typescript@5.4.5_vue@3.4.27/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_Gk27QQmbza from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.4_eslint@8.57.0_floating-vue@5.2.2_ro_2nwyxrbydlkcb72zxj5imipulm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_oNkOX812Fm from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+device@3.1.1_rollup@4.18.0/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_y0HuWNzSVb from "/opt/build/repo/node_modules/.pnpm/nuxt-graphql-client@0.2.34_graphql-tag@2.12.6_rollup@4.18.0_typescript@5.4.5/node_modules/nuxt-graphql-client/dist/runtime/plugin.mjs";
import plugin_eQRlRfKntg from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+algolia@1.10.2_rollup@4.18.0_vue@3.4.27/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import i18n_mkRk4pFumn from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.18.0_vue@3.4.27/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_xFt5xsj4Sa from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.4_eslint@8.57.0_floating-vue@5.2.2_ro_2nwyxrbydlkcb72zxj5imipulm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import click_outside_0pME0VtVLj from "/opt/build/repo/plugins/directives/click-outside.ts";
import google_maps_sKT4qijSb0 from "/opt/build/repo/plugins/google-maps.ts";
import datocms_b65u42IE7w from "/opt/build/repo/plugins/datocms.ts";
import gql_error_zbgPDv3vAX from "/opt/build/repo/plugins/gql-error.ts";
import gtag_client_Zw8EQXNVTz from "/opt/build/repo/plugins/gtag.client.ts";
import lazysizes_ESE1UAS9WQ from "/opt/build/repo/plugins/lazysizes.ts";
import lite_youtube_embed_client_M5n9wqzn0P from "/opt/build/repo/plugins/lite-youtube-embed.client.ts";
import sentry_client_shVUlIjFLk from "/opt/build/repo/plugins/sentry.client.ts";
import vue_gtm_client_stBjLl0OeM from "/opt/build/repo/plugins/vue-gtm.client.ts";
export default [
  revive_payload_client_mHU7AEErat,
  unhead_gCLydIO5af,
  router_EfORKqxfCY,
  payload_client_AveYsmTbJo,
  check_outdated_build_client_Beedx3MULO,
  plugin_vue3_hUtmV96iV1,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Gk27QQmbza,
  plugin_oNkOX812Fm,
  plugin_y0HuWNzSVb,
  plugin_eQRlRfKntg,
  i18n_mkRk4pFumn,
  chunk_reload_client_xFt5xsj4Sa,
  click_outside_0pME0VtVLj,
  google_maps_sKT4qijSb0,
  datocms_b65u42IE7w,
  gql_error_zbgPDv3vAX,
  gtag_client_Zw8EQXNVTz,
  lazysizes_ESE1UAS9WQ,
  lite_youtube_embed_client_M5n9wqzn0P,
  sentry_client_shVUlIjFLk,
  vue_gtm_client_stBjLl0OeM
]