import { DirectiveBinding } from 'vue'

type DetectedMouseEvent = MouseEvent | TouchEvent | Event

interface ClickOutsideElement extends HTMLElement {
  __vueClickEventHandler__: (event: DetectedMouseEvent) => void
}

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('click-outside', {
    mounted: (el: ClickOutsideElement, binding: DirectiveBinding): void => {
      const clickOutsideHandler = (event: DetectedMouseEvent) => {
        if (!el.contains(event.target as HTMLElement) && el !== event.target) {
          binding.value(event)
        }
      }

      el.__vueClickEventHandler__ = clickOutsideHandler

      setTimeout(() => {
        const bodyElement = el.closest('body')
        if (bodyElement) {
          bodyElement.addEventListener('click', clickOutsideHandler)
        } else {
          document.addEventListener('click', clickOutsideHandler)
        }
      }, 0)
    },
    unmounted: (el: ClickOutsideElement): void => {
      const bodyElement = el.closest('body')
      if (bodyElement) {
        bodyElement.removeEventListener('click', el.__vueClickEventHandler__)
      } else {
        document.removeEventListener('click', el.__vueClickEventHandler__)
      }
    }
  })
})
