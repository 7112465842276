import { defineStore } from 'pinia'
// import { useBlogStore } from '~/stores/BlogStore'
import { Site, MenuRecord, MenuFooterRecord, SiteLocale } from '~/types/datocms'

export interface AppState {
  _site: Site
  allMenus: MenuRecord[]
  allMenuFooters: MenuFooterRecord[]
  isLoading: boolean
}

export const useAppStore = defineStore('AppStore', {
  state: (): AppState => {
    return {
      _site: {} as Site,
      allMenus: [],
      allMenuFooters: [],
      isLoading: true
    }
  },
  actions: {
    async fetchData(locale: string) {
      // const blogStore = useBlogStore()
      this.setLoading(true)

      const { data } = await useAsyncGql('Site', {
        locale: locale as unknown as SiteLocale,
        options: {
          initialCache: false
        }
      })

      this._site = data?.value._site as Site
      this.allMenus = data?.value.allMenus as MenuRecord[]
      this.allMenuFooters = data?.value.allMenuFooters as MenuFooterRecord[]
      this.setLoading(false)
      // blogStore.pushCategories(data?.value?.allCategories)
    },
    async updateData(locale: string) {
      // const blogStore = useBlogStore()

      this.setLoading(true)

      const data = await GqlSite({
        locale: locale as SiteLocale
      })

      this._site = data._site as Site
      this.allMenus = data.allMenus as MenuRecord[]
      this.allMenuFooters = data.allMenuFooters as MenuFooterRecord[]
      this.setLoading(false)
      // blogStore.pushCategories(data?.allCategories)
    },
    setLoading(loading: boolean) {
      this.isLoading = loading
    }
  }
})
