export default defineNuxtPlugin(() => {
  const script = document.createElement('script')
  script.async = true
  script.id = 'gtag-init'
  script.innerHTML = `
    // Define dataLayer and the gtag function.
    window.dataLayer = window.dataLayer || []
    function gtag() {
      dataLayer.push(arguments)
    }
   `
  document.head.appendChild(script)

  // Set default consent to 'denied' as a placeholder
  gtag('consent', 'default', {
    ad_storage: 'denied',
    ad_user_data: 'denied',
    ad_personalization: 'denied',
    analytics_storage: 'denied'
  })
})
