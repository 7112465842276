import { paths } from '~/lib/paths'
import { LocaleCode } from '~/types/locales'
import { ModelApiKey } from '~/types/models'

export default function () {
  const { locale } = useI18n()
  const localePath = useLocalePath()

  const localePathByType = (apiKey: string, slug: string) => {
    let path

    switch (apiKey) {
      case 'index':
        path = localePath('/')
        break
      case 'page':
        path = localePath(`/${slug}`)
        break
      case 'PageRecord':
        path = localePath(`/${slug}`)
        break
      case 'article':
        path = localePath(`/${paths[locale.value as LocaleCode][ModelApiKey.Article].path}/${slug}`)
        break
      case 'ArticleRecord':
        path = localePath(`/${paths[locale.value as LocaleCode][ModelApiKey.Article].path}/${slug}`)
        break
      case 'event':
        path = localePath(`/${paths[locale.value as LocaleCode][ModelApiKey.Event].path}/${slug}`)
        break
      case 'offer':
        path = localePath(`/${paths[locale.value as LocaleCode][ModelApiKey.Offer].path}/${slug}`)
        break
      case 'route':
        path = localePath(`/${paths[locale.value as LocaleCode][ModelApiKey.Route].path}/${slug}`)
        break
      case 'RouteRecord':
        path = localePath(`/${paths[locale.value as LocaleCode][ModelApiKey.Route].path}/${slug}`)
        break
      default:
        return null
    }

    return path
  }

  return {
    localePathByType
  }
}
