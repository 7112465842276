<template>
  <NuxtLink
    :to="localePathByType('index', '')"
    class="inline-block text-center"
    :title="$t('logoLink')"
  >
    <img :src="isVariantWhite ? logoWhite : logo" alt="Štajerska Logo" :width="304" :height="66" />
  </NuxtLink>
</template>

<script setup lang="ts">
import logo from '~/assets/images/logo.svg'
import logoWhite from '~/assets/images/logo-white.svg'

const props = defineProps({
  isWhite: {
    type: Boolean,
    default: false
  }
})
const { localePathByType } = useLocalePathByType()
const isMenuVisible = useState('menu')
const isVariantWhite = computed(() => props.isWhite && !isMenuVisible.value)
</script>
